import { BaseURI } from "../..";
import { Executor } from "../../Executor";

export const getTagLogs = (id) => {
  return Executor({
    method: "get",
    url: BaseURI + `/api/redear/TagMouvement/${id}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};
