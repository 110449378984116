import { createSlice } from "@reduxjs/toolkit";

export const MotifsInitialState = {
  motifs: [],
  motifsByClient:[],
  motifsOverview :[]
};

export const MotifsSlice = createSlice({
  name: "motifs",
  initialState: MotifsInitialState,
  reducers: {
    setMotifs: (state, action) => {
      state.motifs = action.payload.motifs;
    },
    setMotifsByClient: (state, action) => {
      state.motifsByClient = action.payload.motifsByClient;
    },
    setMotifsOverview: (state, action) => {
      state.motifsOverview = action.payload.motifsOverview;
    },
  
  },
});

export const { setMotifs,setMotifsByClient,setMotifsOverview } = MotifsSlice.actions;
