import { createSlice } from "@reduxjs/toolkit";

export const AvoirInitialState = {
  avoirs: [],
  avoirLog:[],
};

export const avoirSlice = createSlice({
  name: "avoir",
  initialState: AvoirInitialState,
  reducers: {
    setAvoirs: (state, action) => {
      state.avoirs = action.payload.avoirs;
    },
    setAvoirLog:(state,action)=>{
      state.avoirLog =action.payload.avoirLog
    }
  },
});

export const { setAvoirs ,setAvoirLog} = avoirSlice.actions;
