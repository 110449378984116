import { createSlice } from "@reduxjs/toolkit";

export const TagInitialState = {
  tagLogs: [],
};

export const tagSlice = createSlice({
  name: "tag",
  initialState: TagInitialState,
  reducers: {
    setTagLogs: (state, action) => {
      state.tagLogs = action.payload.tagLogs;
    },
  
  },
});

export const { setTagLogs } = tagSlice.actions;
